<template>
    <div class="page-transition">
        <div class="d-flex justify-content-center align-items-center">
            <div
                class="spinner-border text-primary"
                style="width: 6rem; height: 6rem;"
                role="status"
            >
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.page-transition {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;
    background-color: rgba(0,0,0,.5);

    > div {
        height: 100vh;
    }
}
</style>