import {defineStore,} from 'pinia'
import {clone,} from '@/assets/js/src/shared/Utils'
import {computed,} from 'vue'

export const DELETE_DIALOG_TYPE = 'DELETE'
export const EDIT_DIALOG_TYPE = 'EDIT'
export const CREATE_DIALOG_TYPE = 'CREATE'

export const GLOBAL_CONTACT_DIALOG_TYPE = 'CONTACT'
export const GLOBAL_REPORT_DIALOG_TYPE = 'REPORT'

export const useDialogStore = defineStore('dialog', {
    state () {
        return {
            dialogData: null,
            dialogIdentifier: '',
        }
    },
    getters: {
        isVisibleComputed: (state) => (identifier) => computed(() => (state.dialogIdentifier === identifier)),
        isVisible: (state) => (identifier) => state.dialogIdentifier === identifier,
    },
    actions: {
        showDialog (item, identifier, cb: Function | null = null) {
            this.dialogData = clone(item)
            this.dialogIdentifier = identifier
            if(cb) {
                cb(this.dialogData)
            }
        },
    },
})
