<template>
    <div class="user-avatar">
        <role-switch class="d-none d-lg-inline-block" />
        <transition-link
            as="button"
            :href="$page.props.loggedIn ? '/profile' : '/login'"
            class=" btn btn-dark border-dark-subtle rounded-circle"
            :class="{'overflow-hidden p-0':$page.props.loggedIn && user.image}"
        >
            <template v-if="$page.props.loggedIn && user.image">
                <profile-picture
                    :image="user.image"
                    :classes="null"
                />
            </template>
            <profile-picture
                v-else
                :fallback-icon="$page.props.loggedIn ? 'bi-person-fill' : 'bi-person'"
                :classes="null"
            />
        </transition-link>
        <transition-link
            :href="$page.props.loggedIn ? '/logout' : '/login'"
            class="mx-2 text-decoration-none text-dark-emphasis position-relative"
        >
            {{ $page.props.loggedIn ? 'Abmelden' : 'Anmelden' }}
            <template v-if="$page.props.loggedIn">
                <div
                    class="position-absolute text-truncate"
                    :class="{'home': $page.component === 'Home'}"
                >
                    {{ user.email }}
                </div>
            </template>
        </transition-link>
        <role-switch class="d-block d-lg-none mt-2" />
    </div>
</template>

<script setup lang="ts">
import TransitionLink from '@/assets/js/src/shared/pageTransition/TransitionLink.vue'
import {useUserStateSync,} from '@/assets/js/src/modules/user/useUserStateSync'
import RoleSwitch from '@/assets/js/src/modules/user/RoleSwitch.vue'
import ProfilePicture from '@/assets/js/src/modules/user/ProfilePicture.vue'

let {user,} = useUserStateSync()
</script>

<style lang="scss" scoped>
@import "@/assets/js/src/style/custom.scss";
@import "bootstrap/scss/mixins/breakpoints";

.user-avatar {
    button {
        padding: 6px 10px;
    }

    a > div {
        width: 92px;
        right: 0;
        transform: translate(18px, -16px);
        font-size: 0.75rem;

        @include media-breakpoint-down(lg) {
            &:not(.home) {
                width: auto;
                transform: translate(54%, -16px);
            }
        }
    }
}
</style>
