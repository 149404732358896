import {ServiceWorkerRegError,} from '@/assets/js/src/shared/Utils'

let global = (1, eval)('this')

global.hasLocalStorageAccess = false
try{
    global.localStorage
    global.hasLocalStorageAccess = true
}catch(error){ /* empty */ }

if ('serviceWorker' in navigator && global.hasLocalStorageAccess) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js')
            .catch((registrationError) => {
                console.error(new ServiceWorkerRegError(registrationError?.message))
            })
    })
}