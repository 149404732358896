<template>
    <div
        v-if="$page.props.roles.includes('ROLE_EMPLOYEE') && ($page.props.roles.includes('ROLE_ADMIN') || $page.props.roles.includes('ROLE_BACKEND_USER'))"
        class="me-2"
    >
        <check-input
            v-model="activeEmployeeRole"
            label="Als Mitarbeiter"
            :as-switch="true"
            :inline="true"
        />
    </div>
</template>

<script setup lang="ts">
import {ref, watch,} from 'vue'
import CheckInput from '@/assets/js/src/shared/form/CheckInput.vue'
import {useUserStore,} from '@/assets/js/src/modules/user/user'
import {getActivePinia, } from 'pinia'
import {Api,} from '@/assets/js/src/shared/Api'
import {router,} from '@inertiajs/vue3'

let userStore = useUserStore(getActivePinia())
let activeEmployeeRole = ref(userStore.preference('activeRole') === 'ROLE_EMPLOYEE' || false)

watch(activeEmployeeRole, async (asEmployee) => {
    let activeRole = null
    if (asEmployee) {
        activeRole = 'ROLE_EMPLOYEE'
    }

    Api.post('/user/set-preferences', {
        'activeRole': activeRole,
    }).then(() => {
        router.reload()
    })
})
</script>
