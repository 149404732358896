<template>
    <Link v-bind="attrs">
        <slot />
    </Link>
</template>

<script setup lang="ts">
import {Link,} from '@inertiajs/vue3'
import {usePageTransitionStore,} from '@/assets/js/src/shared/pageTransition/pageTransition.js'
import {getActivePinia, storeToRefs,} from 'pinia'
import {useDialogStore,} from '@/assets/js/src/shared/dialog/dialog'

let pageTransitionStore = usePageTransitionStore(getActivePinia())
const {pageTransition,} = storeToRefs(pageTransitionStore)
const dialogStore = useDialogStore(getActivePinia())

const show = function () {
    pageTransition.value = true
    dialogStore.$reset()
}
const hide = function () {
    pageTransition.value = false
}

const attrs = {
    onStart: show,
    onFinish: hide,
}
</script>
