<template>
    <div v-cloak>
        <Navigation />
        <main class="bg-body">
            <div class="container py-4 px-3 mx-auto">
                <page-transition v-if="pageTrans" />
                <transition
                    name="slide-fade"
                    mode="out-in"
                >
                    <slot />
                </transition>
            </div>
        </main>
        <Footer />
        <Alert />
    </div>
</template>

<script setup lang="ts">
import '@/assets/js/src/style/app.scss'
import Navigation from '@/assets/js/src/shared/layout/Navigation.vue'
import Footer from '@/assets/js/src/shared/layout/Footer.vue'
import Alert from '@/assets/js/src/shared/alert/Alert.vue'
import PageTransition from '@/assets/js/src/shared/pageTransition/PageTransition.vue'
import {usePageTransitionStore,} from '@/assets/js/src/shared/pageTransition/pageTransition.js'
import {getActivePinia, storeToRefs,} from 'pinia'
import {useDark,} from '@vueuse/core'
import { onMounted, } from 'vue'

let pageTransitionStore = usePageTransitionStore(getActivePinia())
const {pageTransition: pageTrans,} = storeToRefs(pageTransitionStore)

useDark({
    selector: 'body',
    attribute: 'data-bs-theme',
    valueDark: 'dark',
    valueLight: 'light',
})

onMounted(() => {
    let splash = document.getElementById('splash-screen')
    splash.classList.add('hidden')
})
</script>

<style lang="scss">
@import "@/assets/js/src/style/custom.scss";
main {
    min-height: calc(100vh - (2 * $navbar-padding-y + 2 * $nav-link-padding-y + $font-size-base * $line-height-base + $footer-min-height));
}

.slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 100ms ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
}
</style>
