import {useUserStore,} from '@/assets/js/src/modules/user/user'
import {getActivePinia, storeToRefs,} from 'pinia'
import {getCurrentInstance, watch,} from 'vue'
import {type User,type Employee,} from '@/assets/js/src/app.d'

export function useUserStateSync () {
    let userStore = useUserStore(getActivePinia())
    const {user,} = storeToRefs(userStore)
    const {proxy: vm,} = getCurrentInstance()

    vm.$page && watch(() => vm.$page, (newPage) => {
        if(newPage.props.loggedIn) {
            user.value = newPage.props.user as User | Employee
        } else {
            user.value = null
        }
    }, {immediate: true,})

    return {
        user,
    }
}
