<template>
    <footer class="bg-body-tertiary text-body-secondary snc-footer pt-5 pb-3 d-print-none">
        <div class="container">
            <div class="row px-3 px-md-0">
                <div class="col-12 offset-md-1 col-md-3 order-2 order-md-1">
                    <ul class="list-unstyled mb-0">
                        <li>
                            <button
                                class="btn btn-link text-body-secondary p-0 text-decoration-none"
                                @click="showAlert1()"
                            >
                                Datenschutz
                            </button>
                        </li>
                        <li>
                            <button
                                class="btn btn-link text-body-secondary p-0 text-decoration-none"
                                @click="showAlert2()"
                            >
                                Impressum
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md-3 order-3 order-md-2">
                    <ul class="list-unstyled mb-0">
                        <li>
                            <button
                                class="btn btn-link text-body-secondary p-0 text-decoration-none"
                                @click="showContactForm()"
                            >
                                Kontakt
                            </button>
                        </li>
                        <li>
                            <button
                                class="btn btn-link text-body-secondary p-0 text-decoration-none"
                                @click="showReportForm()"
                            >
                                Fehler melden
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md-4 text-start text-md-end me-auto order-1 order-md-3">
                    <transition-link href="/">
                        <img
                            v-if="isDark"
                            class="logo mb-3"
                            src="@/assets/images/sinetcon-logo_inv_kl.png"
                            alt="Logo"
                        >
                        <img
                            v-else
                            class="logo mb-3"
                            src="@/assets/images/sinetcon-logo_kl.png"
                            alt="Logo"
                        >
                    </transition-link>
                    <p>
                        Bahnhofstrasse 98<br>
                        36341 Lauterbach
                    </p>
                </div>
                <div class="col-12 text-center small mt-3 order-4">
                    © {{ year }} SiNetCon GmbH
                    <div
                        v-if="$page.props.loggedIn"
                        class="text-center mt-1"
                    >
                        ProfilTool v{{ packageJson.version }}
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <contact-form-dialog v-if="contactDialogVisible" />
    <report-form-dialog v-if="reportDialogVisible" />
</template>

<script setup lang="ts">
import TransitionLink from '@/assets/js/src/shared/pageTransition/TransitionLink.vue'
import {getActivePinia,} from 'pinia'
import { useDialogStore, GLOBAL_CONTACT_DIALOG_TYPE, GLOBAL_REPORT_DIALOG_TYPE, } from '@/assets/js/src/shared/dialog/dialog'
import ContactFormDialog from '@/assets/js/src/modules/home/ContactFormDialog.vue'
import ReportFormDialog from '@/assets/js/src/modules/home/ReportFormDialog.vue'
import {useAlertStore, } from '@/assets/js/src/shared/alert/alert'
import {useDark,} from '@vueuse/core'
import packageJson from '@/package.json'

const isDark = useDark()

let dialogStore = useDialogStore(getActivePinia())
const contactDialogVisible = dialogStore.isVisibleComputed(GLOBAL_CONTACT_DIALOG_TYPE)
const reportDialogVisible = dialogStore.isVisibleComputed(GLOBAL_REPORT_DIALOG_TYPE)

function showContactForm () {
    dialogStore.showDialog(null, GLOBAL_CONTACT_DIALOG_TYPE)
}

function showReportForm () {
    dialogStore.showDialog(null, GLOBAL_REPORT_DIALOG_TYPE)
}

const year = (new Date()).getFullYear()

const alertStore = useAlertStore(getActivePinia())

function showAlert1 () {
    alertStore.showWarning('Es gibt noch keinen Datenschutz!')
}

function showAlert2 () {
    alertStore.showError('Es gibt auch noch kein Impressum...')
}
</script>

<style scoped lang="scss">
@import "@/assets/js/src/style/custom.scss";

footer.snc-footer {
    min-height: $footer-min-height;

    li {
        padding: 4px 0;
    }

    a {
        text-decoration: none;
    }

    .logo {
        width: 170px;
    }
}
</style>
