<template>
    <transition-link
        class="navbar-brand text-primary-emphasis font-monospace fw-bold"
        href="/"
        :style="large?'font-size:32px':''"
    >
        ProfileTool<span
            class="by-sinetcom"
            :class="{'position-relative': large}"
            :style="large?'font-size:24px; transform:translateY(-16px)':''"
        >by SiNetCon GmbH</span>
    </transition-link>
</template>

<script setup lang="ts">
import TransitionLink from '@/assets/js/src/shared/pageTransition/TransitionLink.vue'
import {toRefs,} from 'vue'

const props = defineProps({
    large: {
        type: Boolean,
        default: false,
    },
})

const {large,} = toRefs(props)
</script>

<style lang="scss" scoped>
@import "@/assets/js/src/style/custom.scss";
.by-sinetcom {
    display: block;
    position: absolute;
    font-family: $font-family-base  !important;
    color: $gray-600;
    font-size: .75rem;
    transform: translateY(-8px);
}

.navbar-brand {
    transform: translateY(-8px);
}
</style>
