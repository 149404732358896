<template>
    <div
        v-if="hasAlerts"
        class="alert-parent d-print-none"
    >
        <div
            v-if="hasAlerts > 1"
            class="d-block text-end mb-2"
        >
            <button
                type="button"
                class="btn btn-sm btn-dark shadow"
                @click="dismissAll()"
            >
                Alle verwerfen
            </button>
        </div>
        <div
            v-for="alert in alerts"
            :key="alert.id"
        >
            <div
                class="alert fade show d-flex shadow"
                :class="`alert-${alert.type}`"
                role="alert"
            >
                <span class="me-2">
                    {{ alert.msg }}
                </span>
                <button
                    type="button"
                    class="ms-auto btn-close"
                    aria-label="Schließen"
                    @click="close(alert)"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {getCurrentInstance, watch,} from 'vue'
import {useAlertStore,} from '@/assets/js/src/shared/alert/alert'
import {getActivePinia, storeToRefs,} from 'pinia'

const {proxy: vm,} = getCurrentInstance()
const alertStore = useAlertStore(getActivePinia())
let {alerts,hasAlerts,} = storeToRefs(alertStore)

vm.$page && watch(() => vm.$page, (newPage) => {
    if (Object.keys(newPage.props.flashBag).length) {
        Object.keys(newPage.props.flashBag).forEach((key) => {
            newPage.props.flashBag[key].forEach((msg) => {
                alertStore.showAlert(msg, key)
            })
        })
    }
}, {immediate: true,})

const close = (alert)=>{
    alertStore.dismissAlert(alert.id)
    // timeoutId && clearTimeout(timeoutId)
}

watch(hasAlerts, (hasAlerts) => {
    if(hasAlerts > 0) {
        alerts.value.forEach((alert) => {
            if (!alert.timeoutId) {
                alert.timeoutId = setTimeout(() => {
                    alertStore.dismissAlert(alert.id)
                }, 5000)
            }
        })
    }
}, {immediate: true,})

function dismissAll () {
    alertStore.dismissAll()
}
</script>

<style lang="scss">
.alert-parent {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    z-index: 100000;
}
</style>
