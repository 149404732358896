<template>
    <div
        :class="{
            'mb-3': !inline,
            'form-floating': !inputGroup,
            'input-group': inputGroup,
        }"
    >
        <label
            v-if="inputGroup"
            :for="`input-${uid}`"
            class="input-group-text"
        >
            <slot>
                {{ label }}
            </slot>
        </label>
        <textarea
            v-if="type == 'textarea'"
            :id="`input-${uid}`"
            v-model="inputValue"
            class="form-control"
            :class="{'is-invalid':errorMsg}"
            :aria-describedby="`input-${uid}`"
            :placeholder="placeholder"
            style="height: 12rem;"
            :name="name ? name : undefined"
        />
        <input
            v-else
            :id="`input-${uid}`"
            v-model="inputValue"
            :type="type"
            class="form-control"
            :class="{'is-invalid':errorMsg}"
            :aria-describedby="`input-${uid}`"
            :placeholder="placeholder"
            :name="name ? name : undefined"
        >
        <label
            v-if="!inputGroup"
            :for="`input-${uid}`"
        >
            <slot>
                {{ label }}
            </slot>
        </label>
        <div
            v-if="errorMsg"
            class="invalid-feedback"
            :class="{
                'invalid-feedback': inputGroup,
                'text-danger mb-3': !inputGroup,
                'w-auto position-absolute':inline
            }"
            :style="inline ? 'bottom: -60%;' : undefined"
        >
            {{ errorMsg }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import {computed, getCurrentInstance, toRefs,} from 'vue'

let {uid,} = getCurrentInstance()
const props = defineProps({
    modelValue: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'text',
    },
    errorMsg: {
        type: String,
        default: '',
    },
    inline: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: 'placeholder',
    },
    inputGroup: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        default: '',
    },
})

const emit = defineEmits([ 'update:modelValue', ])
const {modelValue,label,errorMsg,type,inline,placeholder,inputGroup,name,} = toRefs(props)

const inputValue = computed({
    get () {
        return modelValue.value
    },
    set (newValue) {
        emit('update:modelValue',newValue)
    },
})
</script>
