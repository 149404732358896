import {defineStore, getActivePinia,} from 'pinia'
import {Api,} from '@/assets/js/src/shared/Api'
import {useAlertStore,} from '@/assets/js/src/shared/alert/alert'
import {router,} from '@inertiajs/vue3'
import {projectFormatter,} from '@/assets/js/src/modules/project/formatter'
import {type User,type Employee,} from '@/assets/js/src/app.d'

export const useUserStore = defineStore('user', {
    state () {
        return {
            user: {} as User | Employee,
            tmpUser: {},
        }
    },
    getters: {
        skillsFormatted: (state) => {
            if(!('skills' in state.user)) {
                return []
            }

            return state.user.skills.reduce((acc, skill) => {
                if (typeof acc[skill.skillCategory.description] === 'undefined') {
                    acc[skill.skillCategory.description] = {
                        id: skill.skillCategory.id,
                        skills: [],
                    }
                }
                acc[skill.skillCategory.description].skills.push({id: skill.id, name:skill.name,})
                return acc
            }, {})
        },
        projectsFiltered: (state) => {
            if(!('skills' in state.user)) {
                return []
            }
            return state.user.projects
                .map(projectFormatter)
        },
        preference: (state) => (key) => {
            if (!state.user || !state.user.preferences) {
                return null
            }

            if (key in state.user.preferences) {
                return state.user.preferences[key]
            }

            return null
        },
    },
    actions: {
        async saveProfile ({user,}) {
            let alertStore = useAlertStore(getActivePinia())
            let msg = 'Dein Profil wurde erfolgreich geändert.'
            let response = await Api.patch(`/user/${user.id}`, user)

            if (response.formErrors) {
                return response.formErrors
            }

            alertStore.showSuccess(msg)
            router.visit('/profile')
        },
    },
})

export const useEditUserStore = defineStore('editUser', {
    state () {
        return {
            editUser: {} as User | Employee,
        }
    },
    getters: {
        skillsFormatted: (state) => {
            if(!('skills' in state.editUser)) {
                return []
            }

            return state.editUser.skills.reduce((acc, skill) => {
                if (typeof acc[skill.skillCategory.description] === 'undefined') {
                    acc[skill.skillCategory.description] = {
                        id: skill.skillCategory.id,
                        skills: [],
                    }
                }
                acc[skill.skillCategory.description].skills.push({id: skill.id, name:skill.name,})
                return acc
            }, {})
        },
        projectsFiltered: (state) => {
            if(!('skills' in state.editUser)) {
                return []
            }
            return state.editUser.projects
                .map(projectFormatter)
        },
        preference: (state) => (key) => {
            if (!state.editUser || !state.editUser.preferences) {
                return null
            }

            if (key in state.editUser.preferences) {
                return state.editUser.preferences[key]
            }

            return null
        },
    },
    actions: {
        async saveUser ({user = null,}) {
            if (user === null) {
                user = this.editUser
            }

            let alertStore = useAlertStore(getActivePinia())
            let msg = 'Die Nutzerdaten wurden gespeichert.'
            let response = await Api.patch(`/user/${user.id}`, user)

            if (response.formErrors) {
                return response.formErrors
            }

            alertStore.showSuccess(msg)
            router.reload()
            return null
        },
        async saveEmployee ({employee = null,}) {
            if (employee === null) {
                employee = this.editUser
            }

            let alertStore = useAlertStore(getActivePinia())
            let msg = 'Die Mitarbeiterdaten wurden gespeichert.'
            let response = await Api.patch(`/employee/${employee.id}`, employee)

            if (response.formErrors) {
                return response.formErrors
            }

            alertStore.showSuccess(msg)
            router.reload()
            return null
        },
        async createUser ({user = null,}) {
            if (user === null) {
                user = this.editUser
            }

            let alertStore = useAlertStore(getActivePinia())
            let msg = 'Der Nutzer wurde erfolgreich erstellt.'
            let response = await Api.post('/user', user)

            if (response.formErrors) {
                return response.formErrors
            }

            alertStore.showSuccess(msg)
            router.reload({only: [ 'users', ],})

            return null
        },
        async convertToUser ({user = null, options,}) {
            if (user === null) {
                user = this.editUser
            }

            let alertStore = useAlertStore(getActivePinia())
            let msg = 'Der Nutzer wurde erfolgreich angelegt.'
            let response = await Api.post(`/user-from-employee/${user.id}`, options)

            if (response.formErrors) {
                return response.formErrors
            }

            alertStore.showSuccess(msg)
            router.reload({only: [ 'users', ],})

            return null
        },
        async createEmployee ({user = null,}) {
            if (user === null) {
                user = this.editUser
            }

            let alertStore = useAlertStore(getActivePinia())
            let msg = 'Der Mitarbeiter wurde erfolgreich erstellt.'
            let response = await Api.post('/employee', user)

            if (response.formErrors) {
                return response.formErrors
            }

            alertStore.showSuccess(msg)
            router.reload({only: [ 'users', ],})

            return null
        },
        async createEmployeeFromUser ({user = null, goToUsers = false,}) {
            if (user === null) {
                user = this.editUser
            }

            let alertStore = useAlertStore(getActivePinia())
            let msg = 'Der Mitarbeiter wurde erfolgreich erstellt.'
            let response = await Api.post(`/employee-from-user/${user.id}`, user)

            if (response.formErrors) {
                return response.formErrors
            }

            alertStore.showSuccess(msg)
            if (goToUsers) {
                router.visit('/users')
            } else {
                router.reload({only: [ 'users', ],})
            }

            return null
        },
    },
})
