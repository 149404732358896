const _fetch = function (url,data = null,method = 'GET') {
    let options = {
        method,
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'X-Requested-With': 'XMLHttpRequest',
            'X-PureApi': 'true',
        },
    }
    if(data) {
        options['body'] = JSON.stringify(data)
    }

    return fetch(url, options).then(async (response) => {
        if(response.status === 204) {
            return {}
        }
        
        let json = await response.json()

        if([ 401,400, ].includes(response.status)) {
            throw new Error(json.error ?? 'Zugriff verweigert!')
        }
        return json
    })
}

const Api = {
    get: (url) => _fetch(url),
    post: (url,data) => _fetch(url,data,'POST'),
    patch: (url,data) => _fetch(url,data,'PATCH'),
    delete: (url) => _fetch(url,null,'DELETE'),
}

export {
    Api,
}