<template>
    <snc-dialog
        large
    >
        <template #title>
            Kontaktformular
        </template>
        <template #body>
            <form
                novalidate
            >
                <text-input
                    v-model="regarding"
                    :error-msg="hasErrors && formErrors.regarding ? formErrors.regarding : ''"
                    label="Betreff *"
                />
                <text-input
                    v-model="email"
                    label="Email"
                />
                <text-input
                    v-model="message"
                    type="textarea"
                    label="Nachricht *"
                    :error-msg="hasErrors && formErrors.message ? formErrors.message : ''"
                />
            </form>
        </template>
        <template #footer>
            <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
            >
                Abbrechen
            </button>
            <api-button
                class="btn btn-primary"
                :action="sendContactForm"
            >
                Absenden
            </api-button>
        </template>
    </snc-dialog>
</template>

<script setup lang="ts">
import SncDialog from '@/assets/js/src/shared/dialog/Dialog.vue'
import {useDialogStore,} from '@/assets/js/src/shared/dialog/dialog.js'
import {getActivePinia,} from 'pinia'
import {computed, ref,} from 'vue'
import {Api,} from '@/assets/js/src/shared/Api'
import {useAlertStore,} from '@/assets/js/src/shared/alert/alert.js'
import TextInput from '@/assets/js/src/shared/form/TextInput.vue'
import ApiButton from '@/assets/js/src/shared/form/ApiButton.vue'

let dialogStore = useDialogStore(getActivePinia())

let alertStore = useAlertStore(getActivePinia())

const regarding = ref()
const message = ref()
const email = ref()

const formErrors = ref({})
const hasErrors = computed(() => Object.keys(formErrors.value).length)

const sendContactForm = async () => {
    let contactForm = {
        'regarding': regarding.value,
        'message': message.value,
        'email': email.value,
    }
    let msg = 'Die Nachricht wurde gesendet.'
    let response = await Api.post('/contact-form', contactForm)

    if (response.formErrors) {
        formErrors.value = response.formErrors
    } else {
        alertStore.showSuccess(msg)
        dialogStore.$reset()

        regarding.value = ''
        message.value = ''
        email.value = ''
    }
}
</script>
