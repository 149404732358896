import {stripHtml,} from '@/assets/js/src/shared/Utils'
import {type Skill,} from '@/assets/js/src/app.d'
const genDateString = (start,end)=> {
    let options:Intl.DateTimeFormatOptions = {timeZone: 'UTC', month:'2-digit', year:'numeric',}
    start = (new Date(start)).toLocaleDateString('de-DE',options)
    end = end ?
        (new Date(end)).toLocaleDateString('de-DE',options) :
        'heute'
    return `${start} bis ${end}`
}

const genSkillString = (skills:Skill[]) => {
    if(!skills.length) {
        return ''
    }
    return skills.reduce((acc, skill, index)=>{
        return `${acc}${index !== 0 ? ',' : ''} ${skill.name}`
    },'Technisches Umfeld:')
}
export function projectFormatter (project) {
    return {
        id: project.id,
        name: project.name,
        entity: project,
        date: genDateString(project.start, project.end),
        skillsConcat: genSkillString(project.skills),
        location: project.address?.city,
        strippedDescription: stripHtml(project.description),
    }
}
