<template>
    <nav class="navbar navbar-expand-lg shadow d-print-none">
        <div class="container-fluid">
            <profil-tool-logo :class="{'d-none': $page.component === 'Home'}" />
            <button
                :class="{'d-none': $page.component === 'Home'}"
                class="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarToggler"
                aria-controls="navbarToggler"
                aria-expanded="false"
                aria-label="Menü öffnen/schließen"
            >
                <span class="navbar-toggler-icon" />
            </button>
            <div
                id="navbarToggler"
                class="collapse navbar-collapse"
                :class="{'d-flex justify-content-end position-relative': $page.component === 'Home'}"
            >
                <ul
                    class="navbar-nav me-auto mb-2 mb-lg-0"
                    :class="{'d-none': $page.component === 'Home'}"
                >
                    <component
                        :is="comp"
                        v-for="comp in navItems"
                        :key="comp"
                    />
                </ul>
                <div class="nav-extra">
                    <avatar />
                    <UseDark v-slot="{ isDark, toggleDark }">
                        <button
                            class="ms-2 btn btn-light"
                            style="max-height: 2.5rem;"
                            @click="toggleDark()"
                        >
                            <i
                                v-if="isDark"
                                class="bi bi-moon-stars-fill"
                            />
                            <i
                                v-else
                                class="bi bi-sun-fill"
                            />
                        </button>
                    </UseDark>
                </div>
            </div>
            <div
                class="custom-backdrop"
                @click="closeMenu"
            />
        </div>
    </nav>
</template>

<script setup lang="ts">
import { getCurrentInstance, watch, defineAsyncComponent,} from 'vue'
import Avatar from '@/assets/js/src/modules/user/Avatar.vue'
import ProfilToolLogo from '@/assets/js/src/shared/layout/ProfilToolLogo.vue'
import {UseDark,} from '@vueuse/components'

const {proxy: vm,} = getCurrentInstance()

let closeMenu = ()=>{
    const toggler = document.querySelector('.navbar-toggler:not(.collapsed)')
    if(!toggler) {
        return
    }
    toggler.click()
}

vm.$page && watch(() => vm.$page, closeMenu,{immediate:false,})

let navItems = Object.values(import.meta.glob([ '@/assets/js/src/modules/**/*NavItem.vue', ]))
    .map((module) => {
        return defineAsyncComponent(module)
    })
</script>


<style lang="scss" scoped>
@import "@/assets/js/src/style/custom.scss";
@import "bootstrap/scss/mixins/breakpoints";

.navbar-toggler {
    border: none;

    &:not(.collapsed) {
        width: 2.7em;
        height: 1.9em;

        .navbar-toggler-icon {
            background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$navbar-light-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>"));
            width: 1.2rem;
            height: 1.2rem;

            @at-root .dark & {
                background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$navbar-dark-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>"));
            }
        }
    }
}

.navbar-collapse {
    .nav-extra {
        display: flex;
        justify-content: space-between;
    }

    @include media-breakpoint-down(lg) {
        &.show .nav-extra {
            margin: 20px 0
        }
    }
}

@include media-breakpoint-down(lg) {
    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100vw;
        background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
        z-index: 10;
        padding: 0 12px;

        & + .custom-backdrop {
            --bs-backdrop-bg: #000;
            --bs-backdrop-opacity: 0.5;
            position: absolute;
            left: 0;
            height: 100vh;
            width: 100vw;
            z-index: -9;
            top: 100%;
            background-color: var(--bs-backdrop-bg);
            transition: opacity .25s ease-in-out;
            transition-delay: .1s;
            opacity: 0;
        }

        &.collapsing, &.show {
            + .custom-backdrop {
                opacity: var(--bs-backdrop-opacity);
                z-index: 9;
                transition-delay: 0s;
            }
        }
    }
}
</style>
