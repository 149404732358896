<template>
    <button
        type="button"
        :class="classOverride"
        :disabled="isLoading ? 'disabled' : undefined"
        @click="doAction"
    >
        <slot />
        <i
            v-if="hasError"
            class="ms-1 bi bi-exclamation-diamond"
        />
        <div
            v-if="isLoading"
            class="spinner-border spinner-border-sm ms-1"
        >
            <span class="visually-hidden">(Laden ...)</span>
        </div>
    </button>
</template>

<script lang="ts" setup>
import {ref, toRefs,} from 'vue'

const props = defineProps({
    action: {
        type: Function,
        default: () => true,
    },
    class: {
        type: String,
        default: 'btn btn-primary mb-3"',
    },
})

const {action, class: classOverride,} = toRefs(props)
let isLoading = ref(false)
let hasError = ref(false)

const doAction = () => {
    isLoading.value = true
    hasError.value = false

    action.value()
        .then(() => {
        })
        .catch(() => {
            hasError.value = true
        })
        .finally(() => {
            isLoading.value = false
        })
}
</script>

