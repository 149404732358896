<template>
    <div
        class="form-check"
        :class="{'mb-3':!inline, 'form-check-inline': inline, 'form-switch': asSwitch}"
    >
        <input
            :id="`input-${uid}`"
            v-model="inputValue"
            type="checkbox"
            class="form-check-input"
            :class="{'is-invalid':errorMsg}"
            :role="asSwitch ? 'switch' : undefined"
            :disabled="disabled ? 'disabled' : undefined"
        >
        <label
            class="form-check-label"
            :for="`input-${uid}`"
        >
            <slot>
                {{ label }}
            </slot>
        </label>
        <div
            v-if="errorMsg"
            class="text-danger"
        >
            {{ errorMsg }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import {computed, getCurrentInstance, toRefs,} from 'vue'

let {uid,} = getCurrentInstance()
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: '',
    },
    errorMsg: {
        type: String,
        default: '',
    },
    asSwitch: {
        type: Boolean,
        default: false,
    },
    inline: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits([ 'update:modelValue', ])
const {modelValue,label,errorMsg,inline,} = toRefs(props)

const inputValue = computed({
    get () {
        return modelValue.value
    },
    set (newValue) {
        emit('update:modelValue',newValue)
    },
})
</script>
