<template>
    <snc-dialog
        large
    >
        <template #title>
            Fehler melden
        </template>
        <template #body>
            <form
                novalidate
            >
                <text-input
                    v-model="summary"
                    :error-msg="hasErrors && formErrors.summary ? formErrors.summary : ''"
                    label="Zusammenfassung *"
                />
                <div
                    class="form-floating mb-3"
                >
                    <select
                        id="inputGroupSelect01"
                        v-model="reportType"
                        class="form-select"
                    >
                        <option
                            value="Fehler"
                            selected
                        >
                            Fehler
                        </option>
                        <option
                            value="Verbesserungsvorschlag"
                            selected
                        >
                            Verbesserungsvorschlag
                        </option>
                    </select>
                    <label
                        for="inputGroupSelect01"
                    >Fehlerart</label>
                </div>
                <text-input
                    v-model="email"
                    label="Email"
                    description="Um bei Nachfragen in Kontakt treten zu können."
                />
                <text-input
                    v-model="description"
                    :error-msg="hasErrors && formErrors.description ? formErrors.description : ''"
                    label="Beschreibung *"
                    type="textarea"
                />
            </form>
        </template>
        <template #footer>
            <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
            >
                Abbrechen
            </button>
            <api-button
                class="btn btn-primary"
                :action="sendReportForm"
            >
                Absenden
            </api-button>
        </template>
    </snc-dialog>
</template>

<script setup lang="ts">
import SncDialog from '@/assets/js/src/shared/dialog/Dialog.vue'
import {useDialogStore,} from '@/assets/js/src/shared/dialog/dialog.js'
import {getActivePinia,} from 'pinia'
import {computed, ref,} from 'vue'
import {Api,} from '@/assets/js/src/shared/Api'
import {useAlertStore,} from '@/assets/js/src/shared/alert/alert.js'
import TextInput from '@/assets/js/src/shared/form/TextInput.vue'
import ApiButton from '@/assets/js/src/shared/form/ApiButton.vue'

let dialogStore = useDialogStore(getActivePinia())

let alertStore = useAlertStore(getActivePinia())

const summary = ref()
const reportType = ref('Fehler')
const email = ref()
const description = ref()

const formErrors = ref({})
const hasErrors = computed(() => Object.keys(formErrors.value).length)

const sendReportForm = async () => {
    let reportForm = {
        'summary': summary.value,
        'reportType': reportType.value,
        'email': email.value,
        'description': description.value,
    }
    let msg = 'Die Nachricht wurde gesendet.'
    let response = await Api.post('/report-form', reportForm)

    if (response.formErrors) {
        formErrors.value = response.formErrors
    } else {
        alertStore.showSuccess(msg)
        dialogStore.$reset()

        reportType.value = ''
        description.value = ''
        email.value = ''
    }
}
</script>

